@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

@layer base {
  html {
    /*font-family: 'Quicksand', sans-serif;*/
    font-family: 'Roboto Mono', monospace;
  }
}

.hide-down-arrow {
  display: none;
}

.nightTime {
  background-color: #27272a;
  transition-duration: 0ms;
}

.lightTime {
  background-color: #ffffff;
  transition-duration: 0ms;
}